import React from "react";
import styled from "styled-components";

const Tarifs = () => {
  return (
    <div style={styleTarifs}>
      <FicheTarifs className="FicheTarifs">
        <h2>
          Starter Kit: <span>150€ (+50€ Video)</span>
        </h2>
        <p>
          <span>- Pack d’introduction à la communication </span>
        </p>
        <h2>Packshot catalogue:</h2>
        <strong>
          <span> 1-3 Photos : 75€/unité </span>
          <span> 4-6 Photos : 50€/unité </span>
          <span> 7-9 Photos : 40€/unité </span>
        </strong>
        <p>
          <span>- Prise de vue de produits sur fond uni (blanc/noir) </span>
          <span>- 1 à 2 produits sur la même prise de vue </span>
        </p>
        <h2>
          Photo Publicitaire : <span>200€/unité</span>
        </h2>
        <p>
          <span>- Brainstorming ( RDV Téléphonique / Visio Conférence … )</span>
          <span> - Repérage de lieux si nécessaire</span>
          <span> - Recherche stylisme</span>
          <span> - Envoi maquette au client </span>
          <span> - Prise de vue + retouche</span>
          <span> - Cession de droit (prix modulable selon utilisation) </span>
        </p>
        <h2>
          Portraits: : <span>120€ / demi Journée </span>
        </h2>
        <p>
          <span>- 5 Photos travaillées, livrées en format numérique</span>
          <span>- 25€ par photos supplémentaires </span>
        </p>
        <h4>Tarifs à titre indicatifs</h4>
      </FicheTarifs>
    </div>
  );
};

export default Tarifs;

const styleTarifs = {
  minHeight: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const FicheTarifs = styled.div`
  margin-top: 20vh;
  z-index: 0;
  color: rgb(22, 22, 22);
  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 8rem;

    span {
      margin-left: 2rem;
      display: inline-block;
    }
    @media screen and (max-width: 500px) {
      font-size: 1rem;
      margin-left: 0px;
      text-align: center;
    }
  }
  span {
    display: block;
    font-family: "Poppins", sans-serif;
    line-height: 3;
    font-size: 0.8rem;
    text-align: justify;
    margin-left: 15rem;
    text-align: left;
    @media screen and (max-width: 500px) {
      margin-left: 0px;
      text-align: center;
    }
  }

  p {
    margin: 20px;
    font-family: "Poppins", sans-serif;

    span {
      display: block;
      font-family: "Poppins", sans-serif;
      line-height: 3;
      font-size: 0.8rem;
      text-align: justify;
      margin-left: 8rem;
      text-align: left;
      @media screen and (max-width: 500px) {
        margin-left: 10px;
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 1051px) {
    margin-top: 10vh;
  }
  h4 {
    margin-left: 15px;
  }
`;
