import React, { useState, useEffect } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

function ContactComponent() {
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * formes.length);
    setForme(formes[randomIndex]);
    setRandom(randomIndex);
  }, []);

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };


  const [random, setRandom] = useState("");
  const [forme, setForme] = useState(null);

  const formes = [`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M377.1 68.05C364.4 50.65 343.7 40 321.2 40h-13.53c-3.518 0-7.039 .2754-10.53 .8184C284.8 31.33 269.6 26 253.5 26H240c-3.977 0-7.904 .3691-11.75 1.084C216.7 10.71 197.6 0 176 0H160C124.7 0 96 28.65 96 64v49.71L63.04 143.3C43.3 160 32 184.6 32 210.9v78.97c0 32.1 17.11 61.65 44.65 77.12L112 386.9v101.1C112 501.3 122.7 512 135.1 512S160 501.3 160 488v-129.9c-1.316-.6543-2.775-.9199-4.062-1.639l-55.78-31.34C87.72 318.2 80 304.6 80 289.9V210.9c0-12.31 5.281-23.77 14.5-31.39L112 163.8V208C112 216.8 119.2 224 128 224s16-7.156 16-16V64c0-8.828 7.188-16 16-16h16C184.8 48 192 55.17 192 64v16c0 9.578 7.942 16.04 16.15 16.04c6.432 0 12.31-4.018 14.73-10.17C223.3 84.84 228.3 74 240 74h13.53c20.97 0 17.92 19.58 34.27 19.58c8.177 0 9.9-5.584 19.88-5.584h13.53c25.54 0 18.27 28.23 38.66 28.23c.1562 0 .3125-.002 .4668-.0078L375.4 116C388.1 116 400 127.7 400 142V272c0 36.15-19.54 67.32-48 83.69v132.3C352 501.3 362.7 512 375.1 512S400 501.3 400 488v-108.1C430.1 352.8 448 313.6 448 272V142C448 102.1 416.8 69.44 377.1 68.05z"/></svg><strong>PIERRE</strong>`,
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M408 80c-3.994 0-7.91 .3262-11.73 .9551c-9.586-28.51-36.57-49.11-68.27-49.11c-6.457 0-12.72 .8555-18.68 2.457C296.6 13.73 273.9 0 248 0C222.1 0 199.3 13.79 186.6 34.44C180.7 32.85 174.5 32 168.1 32C128.4 32 96.01 64.3 96.01 104v121.6C90.77 224.6 85.41 224 80.01 224c-.0026 0 .0026 0 0 0C36.43 224 0 259.2 0 304.1c0 20.29 7.558 39.52 21.46 54.45l81.25 87.24C141.9 487.9 197.4 512 254.9 512h33.08C393.9 512 480 425.9 480 320V152C480 112.3 447.7 80 408 80zM432 320c0 79.41-64.59 144-143.1 144H254.9c-44.41 0-86.83-18.46-117.1-50.96l-79.76-85.63c-6.202-6.659-9.406-15.4-9.406-23.1c0-22.16 18.53-31.4 31.35-31.4c8.56 0 17.1 3.416 23.42 10.18l26.72 28.69C131.8 312.7 133.9 313.4 135.9 313.4c4.106 0 8.064-3.172 8.064-8.016V104c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24v152C192 264.8 199.2 272 208 272s15.1-7.163 15.1-15.1L224 72c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24v184C272 264.8 279.2 272 288 272s15.99-7.164 15.99-15.1l.0077-152.2c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24v152.2C352 264.8 359.2 272 368 272s15.1-7.163 15.1-15.1V152c0-13.25 10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24V320z"/></svg><strong>FEUILLE</strong>`,
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M40 208c-22.1 0-40 17.9-40 40s17.9 40 40 40l180.2 0c-7.6 8.5-12.2 19.7-12.2 32c0 25.3 19.5 46 44.3 47.9c-7.7 8.5-12.3 19.8-12.3 32.1c0 26.5 21.5 48 48 48l32 0 64 0c70.7 0 128-57.3 128-128l0-113.1c0-40.2-16-78.8-44.4-107.3C444.8 76.8 413.9 64 381.7 64L336 64c-21.3 0-39.3 13.9-45.6 33.1l74.5 23.7c8.4 2.7 13.1 11.7 10.4 20.1s-11.7 13.1-20.1 10.4L288 129.9l0 .1L84 65.8C62.9 59.2 40.5 70.9 33.8 92s5.1 43.5 26.2 50.2L269.5 208 40 208z"/></svg><strong>CISEAUX</strong>`]


  const captcharesolve = () => {
    const captcha = document.getElementById("captcha");
    if (random === 0) {
      if (captcha.value.toLowerCase() === "feuille") {
        return true;
      } else {
        return false;
      }
    }
    if (random === 1) {
      if (captcha.value.toLowerCase() === "ciseaux") {
        return true;
      } else {
        return false;
      }
    }
    if (random === 2) {
      if (captcha.value.toLowerCase() === "pierre") {
        return true;
      } else {
        return false;
      }
    }
  }

  function sendEmail(e) {
    e.preventDefault();
    if (captcharesolve() === true) {
      emailjs
        .sendForm(
          "service_wuobvuk",
          "template_tp4ibp6",
          e.target,
          "user_e60Qd8S4i31lPRJ320hDm"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
      setValue("");
    } else {
      alert("captcha incorrect");
    }

  }
  console.log(forme)
  console.log(random)
  return (
    <ContactContainer>
      <aside style={asideContactStyle}>
        <strong>
          <p>Mail : robinlebonphotographe@gmail.com</p>

          <p>TEL : 0692 74 22 83 </p>
        </strong>
      </aside>
      <Form>
        <form onSubmit={sendEmail}>
          <h3>Remplir le formulaire </h3>
          <div>
            <label>Adresse mail * </label>
            <input
              name="email"
              type="email"
              placeholder="john@mail.com"
              autoComplete="email"
              aria-required="true"
              value={value}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Sujet * </label>
            <input
              type="text"
              placeholder="sujet"
              name="sujet"
              aria-required="true"
            />
          </div>
          <div>
            <label>Message * </label>
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              aria-required="true"
              placeholder="message"
            ></textarea>
          </div>
          <div className="captcha_container">
            <div className="random" dangerouslySetInnerHTML={{ __html: forme }}></div>
            <p>Insérer la forme gagnante</p>
            <input
              type="text"
              name="captcha"
              id="captcha"
              placeholder="pierre / feuille / ciseaux"
              aria-required="true"
            />

          </div>
          <button disabled={!value} type="submit" value="submit">
            <strong> Submit </strong>
          </button>
        </form>
      </Form>
    </ContactContainer>
  );
}

export default ContactComponent;

const ContactContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  margin: 100px 0 74px 0;
`;

const asideContactStyle = {
  minWidth: "20%",
  textDecoration: "none",
  color: "black",
  margin: "0 30px",
  fontFamily: "'Poppins', sans-serif",
  fontSize: "0.8rem",
};

const Form = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  form {
    display: flex;
    flex-direction: column;
    width: 30vw;
    min-width: 250px;
    div {
      display: flex;
      flex-direction: column;
      margin: 20px;
      label {
      }
      input {
        padding: 12px;
        margin: 6px 0 4px;
        border: 1px solid #ccc;
        background: #fafafa;
        font-size: 12px;
        border-radius: 2px;
        max-width: 100%;
        min-width: 250px;
      }
      textarea {
        resize: vertical;
        min-height: 100px;
        max-width: 100%;
        min-width: 250px;
        overflow: auto;
      }
    }
    button {
      color: black;
      border: 2px solid rgb(25, 25, 25);
      padding: 1em 2.5em;
      background: white;
      font-family: "Poppins", sans-serif;
      width: 150px;
      text-align: center;
      text-transform: uppercase;
      margin-left: 20px;
      cursor: pointer;
      &:hover {
        background: black;
        color: white;
      }
    }
    svg {
      width: 50px;
      height: 50px;
    }
    .captcha_container{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 10px 0;
      text-align: center;
      border: 1px solid black;
      width: fit-content;
      padding: 5px;
    }
  }
`;
