import React from "react";
import styled from "styled-components";
import ImageSlider from "../components/imageSlider";
import { SliderData } from "../components/sliderData";

function Publicités() {
  const slidesNofilter = SliderData;
  const slides = slidesNofilter.filter((slide) => slide.filter.includes("pub"));

  return (
    <div className="Publicité-container" style={publicitéContainer}>
      <Spacer className="spacer" />
      <ImageSlider slides={slides} key={slides.image} />
    </div>
  );
}

export default Publicités;

const Spacer = styled.div`
  height: 140px;
  @media screen and (max-width: 1051px) {
    display: none;
  }
`;
const publicitéContainer = {
  minHeight: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
