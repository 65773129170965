import React from "react";
import styled from "styled-components";

function Footer() {
  return (
    <FooterContainer className="Footer">
      <strong>
        <p>
          <a
            href="https://www.facebook.com/LebonRobin/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          |
          <a
            href="https://www.instagram.com/robin_lune/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          |
          <a
            href="https://www.linkedin.com/in/robin-lebon-034293103/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>
        </p>
      </strong>
    </FooterContainer>
  );
}

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  white-space: pre-wrap;
  color: rgba(255, 255, 255, 0.6);
  bottom: 0;

  background-color: #222;
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.15);
    margin: 25px;
    font-family: "Poppin", sans-serif;
    font-size: 0.9rem;
  }
`;
