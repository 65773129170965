import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

function Apropos() {
  return (
    <Bio>
      <DescriptionPicture className="Aside-photo">
        <img src="./ressources/PORTRAIT_WEB/Speakeasy.jpg" alt="Autoportrait" />

        <Aside>
          <Fade bottom>
            <h3>Robin LEBON</h3>
            <p>
              Photographe/Vidéaste Télé-Pilote de Drone S1-S3. Passionné par
              l'image et l'aviation.
            </p>
          </Fade>
        </Aside>
      </DescriptionPicture>

      <Description className="bio">
        <Fade bottom cascade>
          <p>
            <span>
              Mon intérêt pour la photographie s´est d´abord développé via le
              cinéma. Suite à cela, j´ai acquis mon premier appareil photo avec
              lequel j´ai fais mes premières armes en autodidacte, puis en tant
              qu´assistant photographe auprès d´un professionnel à la Réunion.
            </span>
            <br />
            <span>
              Choisissant de faire de ma passion mon métier, et afin
              d´approfondir mes compétences je décide de faire des études de
              photographie à l´école Studio M de Montpellier au sein de laquelle
              j´ai eu la chance d´apprendre auprès de professeurs et de
              photographes renommés.
            </span>
            <br />
            <span>
              Mes études terminées je choisis de retourner vivre sur mon île
              natale pour y développer mon activité de photographe publicitaire.
            </span>
            <br />
            <span>
              Des collaborations avec des influenceurs locaux m’ont permis de
              travailler avec de nombreuses marques locales souhaitant mettre en
              avant leurs produits sur les réseaux.
            </span>
            <br />
            <span>
              Aujourd'hui j’ai à cœur de rendre la photographie publicitaire
              plus accessible aux petites entreprises.
            </span>
          </p>
        </Fade>
      </Description>

      <hr size="1" align="center" width="30%" />
    </Bio>
  );
}

export default Apropos;

const Bio = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 95%;
  margin: 90px auto 0;
`;

const DescriptionPicture = styled.div`
  img {
    width: 37vw;
    max-width: 620px;
    position: absolute;
    top: 0;
    z-index: 1;
    @media screen and (max-width: 1051px) {
      display: none;
    }
  }
`;

const Aside = styled.aside`
  color: blanchedalmond;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24vh;
  left: 10vw;
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 18vw;
  color: rgb(250, 250, 250);
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  font-style: italic;
  @media screen and (max-width: 1051px) {
    display: none;
  }
`;

const Description = styled.div`
  z-index: 0;
  margin-bottom: 20px;
  margin-left: 36vw;
  min-height: 622px;
  p {
    margin: 40px;
    font-family: "Poppins", sans-serif;
    text-align: left;
    span {
      display: block;
      font-family: "Poppins", sans-serif;
      line-height: 2;
      font-size: 0.8rem;
      text-align: justify;
    }
  }
  @media screen and (max-width: 1051px) {
    margin: 0;
  }
`;
