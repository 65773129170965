import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./components/header";
import About from "./pages/About";
import Home from "./pages/Home";
import Footer from "./components/footer";
import Contact from "./pages/Contact";
import Publicités from "./pages/Publicités";
import StarterKit from "./pages/StarterKit";
import Tarifs from "./pages/Tarifs";
import Portraits from "./pages/Portraits";
import Projets from "./pages/Projets";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/starter-kit">
            <StarterKit />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/tarifs">
            <Tarifs />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/publicités">
            <Publicités />
          </Route>
          <Route path="/portraits">
            <Portraits />
          </Route>
          <Route path="/projets">
            <Projets />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
