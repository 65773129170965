import React from "react";
import styled from "styled-components";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const Portraits = () => {
  return (
    <div style={stylePortraits}>
      <h1>PORTRAITS</h1>
      <Lightbox>
        <Gallery>
          <Item
            original="./ressources/PORTRAIT_WEB/_MG_8408-3.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/_MG_8408-3.jpg"
            width="640"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/_MG_8408-3.jpg"
                alt="Portrait Sophie"
              />
            )}
          </Item>

          <Item
            original="./ressources/PORTRAIT_WEB/_MG_8420-2.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/_MG_8420-2.jpg"
            width="640"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/_MG_8420-2.jpg"
                alt="Portrait Sophie"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Carock_Petite_Terre+(2).jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Carock_Petite_Terre+(2).jpg"
            width="584"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Carock_Petite_Terre+(2).jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Carock_Petite_Terre+(1).jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Carock_Petite_Terre+(1).jpg"
            width="534"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Carock_Petite_Terre+(1).jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Carock  (1).jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Carock  (1).jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Carock  (1).jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Carock  (2).jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Carock  (2).jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Carock  (2).jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Carock CapJaune_1.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Carock CapJaune_1.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Carock CapJaune_1.jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Carock CapJaune_2.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Carock CapJaune_2.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Carock CapJaune_2.jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/CArock Victoria Secret_2.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/CArock Victoria Secret_2.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/CArock Victoria Secret_2.jpg"
                alt="Portrait Carock"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/GeekMeAll_1.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/GeekMeAll_1.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/GeekMeAll_1.jpg"
                alt="Portrait Sébastien"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/GeekMeAll_2.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/GeekMeAll_2.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/GeekMeAll_2.jpg"
                alt="Portrait Sébastien"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Fallen_Angel.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Fallen_Angel.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Fallen_Angel.jpg"
                alt="Portrait Daphné"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Fallen _Angel-2.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Fallen _Angel-2.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Fallen _Angel-2.jpg"
                alt="Portrait Daphné"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Ophélie.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Ophélie.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Ophélie.jpg"
                alt="Portrait Ophélie"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Ophélie-2.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Ophélie-2.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Ophélie-2.jpg"
                alt="Portrait Ophélie"
              />
            )}
          </Item>
          <Item
            original="./ressources/PORTRAIT_WEB/Speakeasy.jpg"
            thumbnail="./ressources/PORTRAIT_WEB/Speakeasy.jpg"
            width="800"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PORTRAIT_WEB/Speakeasy.jpg"
                alt="Autoportrait"
              />
            )}
          </Item>
        </Gallery>
      </Lightbox>
    </div>
  );
};

export default Portraits;

const stylePortraits = {
  minHeight: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "120px",
  color: "rgb(22, 22, 22)",
};

const Lightbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  img {
    height: 500px;
    padding: 20px;
    cursor: pointer;
    @media screen and (max-width: 1051px) {
      height: 300px;
    }
  }
`;
