import React from "react";
import styled from "styled-components";
import Apropos from "../components/apropos";
import ContactComponent from "../components/contactComponent";

function Home() {
  return (
    <HomeContainer className="Home">
      <Accueil className="Accueil">
        {/* <h1>Robin LEBON</h1>
        <h2>Auteur Photographe</h2> */}
      </Accueil>
      <Apropos />
      <ContactComponent />
    </HomeContainer>
  );
}

export default Home;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
`;
const Accueil = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  height: 100vh;
  width: 100%;
  background-image: url(./ressources/images/Court_Royale-desktop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h1 {
    font-family: "Playfair Display", serif;
    font-size: 4rem;
    font-weight: bolder;
    letter-spacing: 1px;
    color: rgb(25, 25, 25);
    text-align: center;
    margin: 5px;
    padding-top: 150px;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 3px;
    margin: 40px;
    font-weight: bolder;
    color: rgb(224, 224, 224);
    background-color: rgba(100, 100, 100, 0.4);
    width: 340px;
    border-radius: 10px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {

    // background-size:190%;
     background-position: 45% ;
    h1{
      font-size:3rem;
    }
    h2{
      font-size:1em;
    }
  @media screen and (max-width: 450px) {
    background-image: url(./ressources/images/Court_Royale-mobile.jpg);
    background-size:110%;
     background-position: 30% 50%;
    h1{
      font-size:3rem;
    }
    h2{
      font-size:1em;
    }
`;
