import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
} from "react-router-dom";
import styled from "styled-components";

function Header() {
  const [burgerStatus, setBurgerStatus] = useState(false);
  const toggler = () => {
    burgerStatus ? setBurgerStatus(false) : setBurgerStatus(true);
    console.log(burgerStatus);
  };

  return (
    <ContainerHeader className="Header">
      <LogoBanner className="LogoBanner">
        <Link to="/" style={linkStyle}>
          <h1>Robin LEBON</h1>
          <h2>Auteur Photographe</h2>
        </Link>
      </LogoBanner>
      <Nav show={burgerStatus}>
        <ul>
          <li>
            <NavLink
              to="/starter-kit"
              style={linkStyle}
              activeClassName="selected"
              onClick={toggler}
            >
              Starter Kit
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              style={linkStyle}
              activeClassName="selected"
              onClick={toggler}
            >
              à propos
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/publicités"
              style={linkStyle}
              activeClassName="selected"
              onClick={toggler}
            >
              publicités
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/portraits"
              style={linkStyle}
              activeClassName="selected"
              onClick={toggler}
            >
              portraits
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/projets"
              style={linkStyle}
              activeClassName="selected"
              onClick={toggler}
            >
              Projets
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tarifs"
              style={linkStyle}
              activeClassName="selected"
              onClick={toggler}
            >
              Tarifs
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" style={linkStyleContact} onClick={toggler}>
              Contact
            </NavLink>
          </li>
        </ul>
      </Nav>
      <BurgerWrapper onClick={toggler} className="burger-wrapper">
        <Burger close={burgerStatus} className="burger"></Burger>
      </BurgerWrapper>
    </ContainerHeader>
  );
}

export default Header;

const ContainerHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
  padding: 40px;
  background-color: transparent;
  height: 80px;
  left: 0;
  right: 0;
  z-index: 11000;
  @media screen and (max-width: 1051px) {
    position: fixed;
    background: white;
    margin: 0;
    padding: 0 40px;
    height: 50px;
  }
`;

const LogoBanner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  h1 {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    font-weight: 200;
    margin: 0;
    letter-spacing: 1px;

    color: rgb(22, 22, 22);
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    margin-top: 1px;

    color: rgb(22, 22, 22);
  }
  @media screen and (max-width: 1051px) {
    h1 {
      font-size: 1.5rem;
      width: 200px;
    }
    h2 {
      display: none;
    }
  }
`;

const Nav = styled.nav`
  width: 700px;
  display: flex;
  justify-content: flex-end;
  ul {
    display: flex;
    flex: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;

    li {
      list-style: none;
      font-family: "Poppin", sans-serif;
      font-weight: bold;
      font-size: 0.7rem;
      color: rgb(25, 25, 25);
      text-transform: uppercase;
      margin: 1rem;
    }
  }
  @media screen and (max-width: 1051px) {
    position: absolute;
    top: 50px;
    right: 0;
    height: calc(100vh - 50px);
    width: 200px;
    background: white;
    transform: ${(props) => (props.show ? "translateX(0)" : "translate(100%)")};
    transition: transform 0.2s ease;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      li {
        margin-bottom: 3rem;
      }
    }
  }
`;

const linkStyle = {
  textDecoration: "none",
  color: "black",
};
const linkStyleContact = {
  textDecoration: "none",
  color: "black",
  border: "2px solid rgb(25, 25, 25)",
  padding: "10px 25px",
};

const BurgerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  alignt-items: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  z-index: 11000;
  display: none;
  @media screen and (max-width: 1051px) {
    display: flex;
  }
`;
const Burger = styled.div`
  width: 18px;
  height: 2px;
  background-color: ${(props) => (props.close ? "transparent" : "black")};
  &::before {
    position: absolute;
    content: "";
    background-color: black;
    top: 3px;
    right: 0;
    height: 2px;
    width: 18px;
    transform: ${(props) =>
      props.close
        ? "rotate(-45deg) translateX(-3px) translateY(4px)"
        : "rotate(0deg)"};
        transition transform 0.2s ease-in-out;
  }
  &::after {
    position: absolute;
    content: "";
    background-color: black;
    top: 13px;
    right: 0;
    height: 2px;
    width: 18px;
    transform: ${(props) =>
      props.close
        ? "rotate(45deg) translateX(-3px)translateY(-4px) "
        : "rotate(0deg)"};
        transition transform 0.2s ease-in-out;
  }
`;
