import React from "react";
import styled from "styled-components";

const Dots = ({ slides, current }) => {
  return (
    <div style={styleDots}>
      {slides.map((slide, i) => (
        <Dot key={slide} active={current === i} />
      ))}
    </div>
  );
};

export default Dots;

const Dot = styled.span`
  padding: 0.6rem;
  margin: 5px;
  cursor: pointer;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#111111" : "#c4c4c4")};
`;

const styleDots = {
  // position: "absolute",
  // bottom: "-30px",
  // height: "25px",
  width: "auto",
  borderRadius: "25px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  // backgroundColor: "rgba(0, 0, 0, 0.4)",
};
