import React from "react";
import Apropos from "../components/apropos";
import styled from "styled-components";

function About() {
  return (
    <div style={aboutStyle} className="about-page">
      <Spacer />
      <Apropos />
    </div>
  );
}

export default About;

const Spacer = styled.div`
  height: 70px;
  @media screen and (max-width: 1051px) {
    height: 5px;
  }
`;

const aboutStyle = {
  minHeight: "calc(100vh - 100px)",
};
