import React, { useState, useEffect, useRef } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import styled from "styled-components";
import Dots from "./dots";

function ImageSlider2({ slides }) {
  useEffect(() => {
    autoPlayRef.current = nextSlide;
  });

  useEffect(() => {
    const Play = () => {
      autoPlayRef.current();
    };
    const interval = setInterval(Play, 6000);
  }, []);

  const autoPlayRef = useRef();

  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <Slider className="slider">
      <div>
      <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
      <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
      {slides.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={Math.random() * 5}
          >
            {index === current && (
              <img src={slide.image} alt={slide.alt} style={styleImage} />
            )}
          </div>
        );
      })}
      </div>
      <Dots current={current} slides={slides} key={current} />
    </Slider>
  );
}

export default ImageSlider2;

const Slider = styled.div`
  position: relative;
  height: 500px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1365px) {
    width: 100%;
  }
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #111111;
    z-index: 1000;
    cursor: pointer;
    user-select: none;
    @media screen and (max-width: 1365px) {
      right: 12px;
      font-size: 2rem;
      opacity: 0.8;
    }
  }
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #111111;
    z-index: 1000;
    cursor: pointer;
    user-select: none;
    @media screen and (max-width: 1365px) {
      left: 12px;
      font-size: 2rem;
      opacity: 0.8;
    }
  }
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
    transform: scale(0.92);
  }
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1);
    display: flex;
    justify-content: center;
  }
  img {
    max-height: 500px;
    width: auto;
    @media screen and (max-width: 800px) {
      height: 80%;
      width: 80%;
    }
  }
`;

const styleImage = {};
