import React from "react";
import styled from "styled-components";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const Projets = () => {
  return (
    <div style={styleProjets}>
      <h1>RECHERCHES ARTISTIQUE</h1>
      <Lightbox>
        <Gallery>
          <Item
            original="./ressources/PROJET_WEB/Court_Royale (2).jpg"
            thumbnail="./ressources/PROJET_WEB/Court_Royale (2).jpg"
            width="800"
            height="533"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Court_Royale (2).jpg"
                alt="Pub Nike"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Court_Royale (3).jpg"
            thumbnail="./ressources/PROJET_WEB/Court_Royale (3).jpg"
            width="800"
            height="533"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Court_Royale (3).jpg"
                alt="Pub Nike"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/_MG_9579-2.jpg"
            thumbnail="./ressources/PROJET_WEB/_MG_9579-2.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/_MG_9579-2.jpg"
                alt="Pub Hoegaarden"
              />
            )}
          </Item>
        </Gallery>
      </Lightbox>
      <h1>MIROIR</h1>

      <Lightbox2>
        <Gallery>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Adrien.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Adrien.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Adrien.jpg"
                alt="Miroir Adrien"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Alex.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Alex.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Alex.jpg"
                alt="Miroir Alex"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Camille.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Camille.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Camille.jpg"
                alt="Miroir Camille"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Robin.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Robin.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Robin.jpg"
                alt="Miroir Robin"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Caroline.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Caroline.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Caroline.jpg"
                alt="Miroir Caroline"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Damien.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Damien.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Damien.jpg"
                alt="Miroir Damien"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Frédérique.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Frédérique.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Frédérique.jpg"
                alt="Miroir Frédérique"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Mamie.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Mamie.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Mamie.jpg"
                alt="Miroir Mamie"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Daniel.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Daniel.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Daniel.jpg"
                alt="Miroir Daniel"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Olive.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Olive.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Olive.jpg"
                alt="Miroir Olive"
              />
            )}
          </Item>
          <Item
            original="./ressources/PROJET_WEB/Miroir_Robin_new.jpg"
            thumbnail="./ressources/PROJET_WEB/Miroir_Robin_new.jpg"
            width="533"
            height="800"
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src="./ressources/PROJET_WEB/Miroir_Robin_new.jpg"
                alt="Miroir Moi"
              />
            )}
          </Item>
        </Gallery>
      </Lightbox2>
    </div>
  );
};

export default Projets;

const styleProjets = {
  minHeight: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "120px",
  color: "rgb(22, 22, 22)",
  textAlign: "center",
};

const Lightbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  img {
    height: 300px;
    padding: 20px;
    cursor: pointer;
    @media screen and (max-width: 1051px) {
      height: 200px;
    }
  }
`;
const Lightbox2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  img {
    height: 500px;
    padding: 20px;
    cursor: pointer;
    @media screen and (max-width: 1051px) {
      height: 300px;
    }
  }
`;
