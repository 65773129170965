import {React, ReactDOM} from "react";

import styled from "styled-components";
import ContactComponent from "../components/contactComponent";

function Contact() {
  return (
    <div style={contactStyle}>
      <Spacer />
      <ContactComponent />
    </div>
  );
}

export default Contact;

const Spacer = styled.div`
  height: 90px;
`;
const contactStyle = {
  minHeight: "calc(100vh - 100px)",
};



// if (document.getElementById("contact")) {
//   ReactDOM.render(<Contact />, document.getElementById("contact"));
// }else{
//   console.log("Not Found");
// }
// while (document.getElmementById('contact')===null){
//   console.log("Not Found");
// break;
// }