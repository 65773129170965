import React from "react";
import styled from "styled-components";
import ImageSlider2 from "../components/imageSlider2";
import { SliderData } from "../components/sliderData";

const StarterKit = () => {
  const slides = SliderData.filter((slide) => slide.filter.includes("starter"));

  return (
    <div style={styleStarterkit}>
      <Spacer />
      <Description className="description">
        <h1>
          Starter Kit* <span>150€</span>
        </h1>
        <p>
          <strong>
            <span>Découvrez le starter kit</span>
            <span>
              Un Pack pensé et créé pour aider les entreprises impactées par la
              crise Covid.
            </span>
            <span>Un moyen de (re)lancer sa com'</span>
            <span>Sans exploser son budget.</span>
          </strong>
        </p>
      </Description>
      <ImageSlider2 slides={slides} key={slides.image} />
      <Description2 className="description">
        <h2>L’offre Comprend :</h2>
        <p>
          <strong>
            <span>- 1 Photographie publicitaire de votre produit phare </span>
          </strong>
          <strong>
            <span>- 1 Photographie Atelier / créateur / ENSEIGNE</span>
          </strong>
          <strong>
            <span>
              - Statut « client premium » qui vous offre -20% sur les prochaines
              factures
            </span>
          </strong>
        </p>
        <h2>Pour 50€ de plus: </h2>
        <p>
          <strong>
            <span>- 1 VIDEO promotionnelle de 15 à 30s. </span>
          </strong>
        </p>
        <p>*cette offre n’est valable qu’une fois par entreprise </p>
      </Description2>
    </div>
  );
};

export default StarterKit;

const Spacer = styled.div`
  height: 90px;
`;
const styleStarterkit = {
  minHeight: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const Description = styled.div`
  z-index: 0;
  margin: 10vh 0 0 0;
  color: rgb(22, 22, 22);
  h1 {
    font-size: 3rem;
    text-transform: uppercase;
    margin-left: -15vw;
    span {
      margin-left: 3rem;
    }
    @media screen and (max-width: 800px) {
      font-size: 2rem;
      margin-left: 0px;
      text-align: center;
    }
  }
  p {
    margin: 40px;
    font-family: "Poppins", sans-serif;

    span {
      display: block;
      font-family: "Poppins", sans-serif;
      line-height: 2;
      font-size: 0.8rem;
      text-align: justify;
      margin: 20px;
      text-align: center;
    }
  }
  @media screen and (max-width: 1051px) {
    margin: 0;
  }
`;
const Description2 = styled.div`
  z-index: 0;
  margin: 10vh 0 0 0;
  color: rgb(22, 22, 22);
  font-size: 0.8rem;
  h2 {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-left: -15vw;
    span {
      margin-left: 3rem;
    }
    @media screen and (max-width: 800px) {
      margin-top: 55px;
      font-size: 1.5rem;
      margin-left: 0px;
      text-align: center;
    }
  }
  p {
    margin: 40px;
    font-family: "Poppins", sans-serif;

    span {
      display: block;
      font-family: "Poppins", sans-serif;
      line-height: 2;
      text-align: left;
      margin: 20px;
    }
  }
  @media screen and (max-width: 1051px) {
    margin: 0;
  }
`;
