export const SliderData = [
  {
    image: "./ressources/PUB_WEB/EGC_1.jpg",
    alt: "Photo EGC",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/EGC_2.jpg",
    alt: "Photo EGC",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/EGC_3.jpg",
    alt: "Photo EGC",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/MANEO_Ferrari-(1).jpg",
    alt: "Photo MANEO FERRARI",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/MANEO_Ferrari-(3).jpg",
    alt: "Photo MANEO FERRARI",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/MANEO_Ferrari-(5).jpg",
    alt: "Photo MANEO FERRARI",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Optique_Duo_Femme-(1)_v2+Logo.jpg",
    alt: "Photo MANEO DUO FEMME",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Optique_Duo_Femme-(2)+Logo.jpg",
    alt: "Photo MANEO DUO ",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Optique_Seul_Homme.jpg",
    alt: "Photo MANEO HOMME",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Quay_Australia_CAROCK_02.jpg",
    alt: "Photo HOP Quay Australia",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Quay_Australia_CAROCK_10.jpg",
    alt: "Photo HOP Quay Australia",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Quay_Australia_CAROCK_12.jpg",
    alt: "Photo HOP Quay Australia",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Quay_Australia_UTOPIA_12.jpg",
    alt: "Photo HOP Quay Australia",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Quay_Australia_UTOPIA_13.jpg",
    alt: "Photo HOP Quay Australia",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/Quay_Australia_UTOPIA_22.jpg",
    alt: "Photo HOP Quay Australia",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/CarockXfiat500_1.jpg",
    alt: "Photo fiat 500",
    filter: "pub",
  },
  {
    image: "./ressources/PUB_WEB/CarockXfiat500_2.jpg",
    alt: "Photo fiat 500",
    filter: "pub",
  },
  {
    image: "./ressources/STARTERKIT_WEB/_MG_9579-2.jpg",
    alt: "Photo ",
    filter: "starter",
  },
  {
    image: "./ressources/STARTERKIT_WEB/Court_Royale-(2).jpg",
    alt: "Photo ",
    filter: "starter",
  },
  {
    image: "./ressources/STARTERKIT_WEB/Court_Royale-(3).jpg",
    alt: "Photo ",
    filter: "starter",
  },
  {
    image: "./ressources/STARTERKIT_WEB/MANEO_Ferrari-(3).jpg",
    alt: "Photo ",
    filter: "starter",
  },
  {
    image: "./ressources/STARTERKIT_WEB/Optique_Duo_Femme(2).jpg",
    alt: "Photo ",
    filter: "starter",
  },
];
